$(document).foundation();

// @codekit-prepend "jquery.fancybox.min.js";

/*var nav_prev = 0;
var header_height = 180;
var window_width = $(window).width();

$(window).resize(function() {
    window_width = $(window).width();
   if (window_width < 768) {
       header_height = 0;
   }
});

$(window).on('scroll', function(){
    if (window_width < 768) {
        header_height = 0;
    }
    var scrollTop = $(window).scrollTop();

    $('.scrollhide-nav').toggleClass('fixed', scrollTop > header_height);
    $('.scrollhide-nav.fixed').toggleClass('hidden', scrollTop > nav_prev);
    nav_prev = scrollTop;
});*/